<template>
  <div class="signing-loan-agreement">
    <v-container class="pa-0 mb-0 mb-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-row>
            <v-col cols="12">
              <h1 class="header-h1">Signing loan agreement</h1>
            </v-col>

            <!-- Loan amount -->
            <InnerCard>
              <!-- Loan amount -->
              <p class="b-body-1 gray800--text">Loan amount</p>

              <h2 class="header-h2 mt-n3">
                R
                {{
                  auth &&
                  auth.user &&
                  auth.user.open_application &&
                  auth.user.open_application.amount
                    ? auth.user.open_application.amount
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$& ")
                    : ""
                }}
              </h2>

              <div class="d-flex flex-column flex-sm-row">
                <p class="b-body-1 mt-sm-1">Repayment date</p>

                <p class="b-body-1 font-weight-bold mt-n4 mt-sm-1 ml-sm-1">
                  {{
                    auth &&
                    auth.user &&
                    auth.user.open_application &&
                    auth.user.open_application.maturity_date
                      ? moment(auth.user.open_application.maturity_date).format(
                          "dddd, DD MMMM YYYY"
                        )
                      : ""
                  }}
                </p>
              </div>
            </InnerCard>

            <v-col cols="12">
              <!-- Desktop -->
              <div class="hidden-xs-only">
                <!-- Pre-agreement and quotation -->
                <div class="d-flex align-center mt-n5">
                  <v-checkbox
                    v-model="preAgreementAndQuotation"
                    :rules="preAgreementAndQuotationRules"
                    required
                    class="gray800--text"
                  >
                  </v-checkbox>

                  <p class="b-body-1 pa-0 ma-0">
                    I have read and agree with the
                    <a
                      class="text-decoration-underline"
                      @click="downloadQuotationDocument"
                    >
                      <span class="text-no-wrap">Pre-agreement</span> and
                      quotation
                    </a>
                  </p>
                </div>

                <!-- Loan agreement -->
                <div class="d-flex align-center mt-n9">
                  <v-checkbox
                    v-model="loanAgreement"
                    :rules="loanAgreementRules"
                    required
                    class="gray800--text"
                  >
                  </v-checkbox>

                  <p class="b-body-1 pa-0 ma-0">
                    I have read and agree with the
                    <a
                      class="text-decoration-underline"
                      @click="downloadAgreementDocument"
                      >Loan agreement</a
                    >
                  </p>
                </div>

                <!-- debit order -->
                <div class="d-flex align-center mt-n9">
                  <v-checkbox
                    v-model="debitOrder"
                    :rules="debitOrderRules"
                    required
                    class="gray800--text"
                  >
                  </v-checkbox>
                  <p class="b-body-1 pa-0 ma-0">
                    I will not dispute my debit order
                  </p>
                </div>
              </div>

              <!-- Mobile -->
              <div class="hidden-sm-and-up">
                <!-- Pre-agreement and quotation -->
                <div class="d-flex align-start mt-n5">
                  <v-checkbox
                    v-model="preAgreementAndQuotation"
                    :rules="preAgreementAndQuotationRules"
                    required
                    class="gray800--text"
                  >
                  </v-checkbox>

                  <p class="b-body-1 mt-5">
                    I have read and agree with the
                    <a
                      class="text-decoration-underline"
                      @click="downloadQuotationDocument"
                    >
                      <span class="text-no-wrap">Pre-agreement</span> and
                      quotation
                    </a>
                  </p>
                </div>

                <!-- Loan agreement -->
                <div class="d-flex align-start mt-n7">
                  <v-checkbox
                    v-model="loanAgreement"
                    :rules="loanAgreementRules"
                    required
                    class="gray800--text"
                  >
                  </v-checkbox>

                  <p class="b-body-1 mt-5">
                    I have read and agree with the
                    <a
                      class="text-decoration-underline"
                      @click="downloadAgreementDocument"
                      >Loan agreement</a
                    >
                  </p>
                </div>

                <!-- debit order -->
                <div class="d-flex align-start mt-n7">
                  <v-checkbox
                    v-model="debitOrder"
                    :rules="debitOrderRules"
                    required
                    class="gray800--text"
                  >
                  </v-checkbox>
                  <p class="b-body-1 mt-5">I will not dispute my debit order</p>
                </div>
              </div>

              <!--  -->
              <div v-if="!smsSend">
                <!-- Desktop -->
                <div class="hidden-xs-only">
                  <v-btn
                    @click="validateForm"
                    class="b-button primary text-capitalize"
                    height="48"
                    width="180"
                    elevation="0"
                    :disabled="!readyToSubmit"
                  >
                    Get SMS-code
                  </v-btn>
                </div>

                <!-- Mobile -->
                <div class="hidden-sm-and-up">
                  <v-btn
                    @click="validateForm"
                    class="b-button primary text-capitalize mr-n2"
                    height="48"
                    width="100%"
                    elevation="0"
                    :disabled="!readyToSubmit"
                  >
                    Get SMS-code
                  </v-btn>
                </div>

                <!-- <v-btn @click="payLoan" class="mt-4">Pay Now</v-btn> -->
              </div>
            </v-col>

            <v-col v-if="smsSend" cols="12" class="mt-n6">
              <!-- Confirmation code -->
              <h4 class="header-h4">Confirmation code</h4>

              <p class="b-body-1 mt-1">
                Please enter your individual SMS-code <br />
                to sign the loan agreement
              </p>

              <v-form ref="form" lazy-validation>
                <!-- SMS-code -->
                <p class="b-body-1 mb-1">SMS-code</p>

                <div class="d-flex flex-column flex-sm-row align-sm-center">
                  <v-card width="170" flat>
                    <v-text-field
                      class="py-0 my-0"
                      v-model="otp"
                      :rules="otpRules"
                      height="48px"
                      full-width
                      outlined
                      required
                    >
                    </v-text-field>
                  </v-card>

                  <!-- Get a new code -->
                  <!-- allowOtpReSend -->
                  <p v-if="!allowOtpReSend" class="b-body-1 ml-sm-5 mt-sm-n1">
                    <span class="font-weight-bold gray600--text"
                      >Get a new code</span
                    >
                    <countdown :time="time">
                      <template slot-scope="props">
                        {{ props.minutes }}:{{ props.seconds }}
                      </template>
                    </countdown>
                  </p>

                  <!-- allowOtpReSend -->
                  <p
                    v-else
                    @click="getRequestDocumentOTP()"
                    class="b-body-1 primary--text font-weight-bold ml-sm-5 mt-sm-n1 pointer"
                  >
                    Get a new code
                  </p>
                </div>
              </v-form>
            </v-col>
          </v-row>

          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="1000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>

          <v-dialog v-model="dialog" width="850" height="600">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                Click Me
              </v-btn>
            </template> -->

            <v-card width="100%" height="600" flat>
              <object
                :data="agreementDocumentDataSrc"
                type="application/pdf"
                height="100%"
                width="100%"
              >
                <iframe
                  src="https://docs.google.com/viewer?&embedded=true"
                  frameBorder="0"
                  scrolling="auto"
                  height="100%"
                  width="100%"
                ></iframe>
              </object>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import InnerCard from "@/components/shared/cards/InnerCard";

export default {
  components: { InnerCard },

  name: "LimeLoansSigningLoanAgreement",

  data() {
    return {
      overlay: false,
      readyToSubmit: false,
      smsSend: false,

      dialog: false,
      agreementDocumentDataSrc: "",

      // Pre-agreement and quotation
      preAgreementAndQuotation: false,
      preAgreementAndQuotationRules: [(v) => !!v || ""],

      // Loan agreement
      loanAgreement: false,
      loanAgreementRules: [(v) => !!v || ""],

      // debit order
      debitOrder: false,
      debitOrderRules: [(v) => !!v || ""],

      countDown: 0,
      time: 0,

      error: false,
      otpSend: false,
      allowOtpReSend: false,

      // otp
      otp: "",
      otpRules: [
        (v) => !!v || "",
        (v) => (v && !this.otpHasError) || this.otpErrorMessage,
        (v) => /^[0-9]+$/.test(v) || "Otp must contain only 0 to 9",
      ],
      otpHasError: false,
      otpErrorMessage: "",
    };
  },

  computed: {
    ...mapState({
      auth: "auth",
      document: "document",
      requestDocumentOTP: "requestDocumentOTP",
    }),
  },

  created() {
    this.generateDocuments();

    this.$helpers.resetErrorHandlerState();
  },

  watch: {
    // preAgreementAndQuotation
    preAgreementAndQuotation() {
      this.setReadyToSubmit();
    },

    // loanAgreement
    loanAgreement() {
      this.setReadyToSubmit();
    },

    // debitOrder
    debitOrder() {
      this.setReadyToSubmit();
    },

    // otp
    otp() {
      if (this.otpHasError) {
        this.otpHasError = false;
        this.otpErrorMessage = "";
      }

      if (this.otp.length == 5) {
        this.setOtp(this.otp);

        this.sendSignDocument();
      }
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiAgreementDocument: "document/getApiAgreementDocument",
      getApiQuotationDocument: "document/getApiQuotationDocument",

      getApiRequestDocumentOTP: "requestDocumentOTP/getApiRequestDocumentOTP",
      sendApiSignDocument: "signDocument/sendApiSignDocument",

      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapMutations({
      setOtp: "signDocument/setOtp",
    }),

    // payLoan
    async payLoan() {
      this.overlay = true;

      try {
        var reqData = {
          merchantTransactionId: "Checkout_OdeK7DI3qkU0br9",
          amount: 14,
          nonce: "UNQ00012345676",
        };

        // console.log("reqData", reqData);

        const res = await this.axios.post("create-checkout", reqData);
        // console.log("res");
        // console.log(res);

        if (res.status == 200) {
          const data = res.data;
          console.log("data 200");
          console.log(data.checkoutId);
          console.log(data.entityId);

          this.$router.push({
            name: "DashboardPayLoan",
            query: {
              checkoutId: data.checkoutId,
              entityId: data.entityId,
            },
          });
        }
      } catch (error) {
        let data = {};
        console.log("data error");
        console.log(data);
      }

      this.overlay = false;
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        !this.preAgreementAndQuotation ||
        !this.loanAgreement ||
        !this.debitOrder
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    // validateForm
    async validateForm() {
      await this.getRequestDocumentOTP();

      if (this.requestDocumentOTP.result != "") {
        this.smsSend = true;
      }

      if (this.smsSend) {
        this.countDown = 2 * 60;
        this.time = 2 * 60 * 1000;

        this.countDownTimer();
      }
    },

    // countDownTimer
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        // this.smsSend = false;
        this.allowOtpReSend = true;
      }
    },

    // generateDocument
    async generateDocuments() {
      this.overlay = true;

      if (this.document.agreementDocumentData == "") {
        const agreementDocumentRes = await this.getApiAgreementDocument();

        if (agreementDocumentRes.status == 200) {
          //
        } else if (agreementDocumentRes.status == 401) {
          this.logOutUser();
        } else if (agreementDocumentRes.status == 422) {
          const data = agreementDocumentRes.data;

          // idnumber
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          // id_number
          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }

          if (this.$refs.form) {
            this.$refs.form.validate();
          }

          this.setReadyToSubmit();
        } else {
          this.$helpers.handleError(
            agreementDocumentRes.status,
            agreementDocumentRes.data
          );
        }
      }

      if (this.document.quotationDocumentData == "") {
        const quotationDocumentRes = await this.getApiQuotationDocument();

        if (quotationDocumentRes.status == 200) {
          //
        } else if (quotationDocumentRes.status == 401) {
          this.logOutUser();
        } else if (quotationDocumentRes.status == 422) {
          const data = quotationDocumentRes.data;

          // idnumber
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          // id_number
          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }
        } else {
          this.$helpers.handleError(
            quotationDocumentRes.status,
            quotationDocumentRes.data
          );
        }
      }

      this.overlay = false;
    },

    // getRequestDocumentOTP
    async getRequestDocumentOTP() {
      this.overlay = true;

      const requestDocumentOTPRes = await this.getApiRequestDocumentOTP();

      if (requestDocumentOTPRes.status == 200) {
        this.smsSend = true;

        if ("dev_otp" in requestDocumentOTPRes.data) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Your OTP is " + requestDocumentOTPRes.data.dev_otp,
            showConfirmButton: false,
            timer: 30000,
            timerProgressBar: true,
          });
        }
      } else if (requestDocumentOTPRes.status == 401) {
        this.logOutUser();
      } else if (requestDocumentOTPRes.status == 422) {
        const data = requestDocumentOTPRes.data;

        // idnumber
        if ("idnumber" in data) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.idnumber[0],
          });
        }

        // id_number
        if ("id_number" in data) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.id_number[0],
          });
        }
      } else {
        this.$helpers.handleError(
          requestDocumentOTPRes.status,
          requestDocumentOTPRes.data
        );
      }

      this.overlay = false;
    },

    // sendSignDocument
    async sendSignDocument() {
      this.overlay = true;

      const signDocumentRes = await this.sendApiSignDocument();

      if (signDocumentRes.status == 200) {
        await this.getApiUserDetails();

        if (
          this.auth &&
          this.auth.user &&
          this.auth.user.open_application &&
          this.auth.user.open_application.has_signed_agreement
        ) {
          // sendEvent
          this.sendEvent();

          this.$router.push({ name: "DashboardDebitOrderApprovalPage" });
        }
      } else if (signDocumentRes.status == 401) {
        this.logOutUser();
      } else if (signDocumentRes.status == 422) {
        const data = signDocumentRes.data;

        // document_signature_otp
        if ("document_signature_otp" in data) {
          this.otpHasError = true;
          this.otpErrorMessage = data.document_signature_otp[0];

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }

        // idnumber
        if ("idnumber" in data) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.idnumber[0],
          });
        }

        // id_number
        if ("id_number" in data) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.id_number[0],
          });
        }

        if (this.$refs.form) {
          this.$refs.form.validate();
        }

        this.setReadyToSubmit();
      } else {
        this.$helpers.handleError(signDocumentRes.status, signDocumentRes.data);
      }

      this.overlay = false;
    },

    // downloadAgreementDocument
    downloadAgreementDocument() {
      this.overlay = true;

      this.$helpers.downloadBase64PDF(
        this.document.agreementDocumentData,
        `Loan agreement`
      );

      this.overlay = false;
    },

    // downloadQuotationDocument
    downloadQuotationDocument() {
      this.overlay = true;

      this.$helpers.downloadBase64PDF(
        this.document.quotationDocumentData,
        `Pre-agreement and quotation`
      );

      this.overlay = false;
    },

    // viewAgreementDocumentData
    viewAgreementDocumentData() {
      this.dialog = true;

      this.agreementDocumentDataSrc = `data:application/pdf;base64,${this.document.quotationDocumentData}`;
    },

    // sendEvent
    sendEvent() {
      try {
        if (this.$gtm.enabled()) {
          window.dataLayer?.push({
            event: "application",
            "event-param": "sign_loan_sms",
            "client-id": this.$store.getters["auth/getIdNumber"],
            "loan-count": this.$store.getters["auth/getLoansCount"],
          });
        }
      } catch (error) {
        if (error) {
          //
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
