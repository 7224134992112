<template>
  <div>
    <!-- Payment Form -->
    <div id="payment-form"></div>

    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "PayLoan",

  data() {
    return {
      overlay: false,
      entityId: "",
      checkoutId: "",
    };
  },

  created() {
    this.checkoutId = this.$route.query.checkoutId;
    this.entityId = this.$route.query.entityId;

    console.log("checkoutId:", this.checkoutId);
    console.log("entityId:", this.entityId);

    // You can now use checkoutId and entityId as needed
  },

  mounted() {
    this.initializeCheckout();
  },

  methods: {
    initializeCheckout() {
      // Load the Peach Payments script dynamically
      const script = document.createElement("script");
      script.src = "https://sandbox-checkout.peachpayments.com/js/checkout.js";
      script.onload = () => {
        this.setupCheckout();
      };
      document.head.appendChild(script);
    },

    setupCheckout() {
      const checkout = Checkout.initiate({
        key: this.entityId,
        checkoutId: this.checkoutId,
        options: {
          theme: {
            brand: {
              primary: "#ff0000",
            },
            cards: {
              background: "#ffffff",
              backgroundHover: "#F3F3F4",
            },
          },
          ordering: {
            PAYFLEX: 1,
            CARD: 2,
          },
        },
        events: {
          onCompleted: (event) => {
            console.log("Checkout Completed:", event);
            this.handleCheckoutCompletion(event);
          },
          onCancelled: (event) => {
            console.log("Checkout Cancelled:", event);
            this.handleCheckoutCancellation(event);
          },
          onExpired: (event) => {
            console.log("Checkout Expired:", event);
            this.handleCheckoutExpiration(event);
          },
        },
      });

      checkout.render("#payment-form");
    },

    handleCheckoutCompletion(event) {
      // Handle the checkout completion event
      this.overlay = false;
      console.log("Payment successful!", event);
      // You can redirect the user or show a success message here
    },

    handleCheckoutCancellation(event) {
      // Handle the checkout cancellation event
      this.overlay = false;
      console.log("Payment cancelled!", event);
      // You can redirect the user or show a cancellation message here
    },

    handleCheckoutExpiration(event) {
      // Handle the checkout expiration event
      this.overlay = false;
      console.log("Payment expired!", event);
      // You can redirect the user or show an expiration message here
    },
  },
};
</script>

<style lang="scss" scoped>
// Your styles here

#payment-form {
  height: 500px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}
</style>
